import React from 'react';
import PropTypes from 'prop-types';

const Topics = props => {
  const { tags } = props;
  return (
    <div className="post__footer__topicsTags">
      <h4 className="topics__title">Explore Topics</h4>
      <div className="topics__container">
        {tags?.map(tag => (
          <div key={tag.slug} className="topic_tag">
            <a
              href={`https://www.fastcompany.com/section/${tag?.slug}`}
              className="topic_tag_link"
            >
              {tag?.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

Topics.defaultProps = {
  tags: []
};

Topics.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  )
};

export default Topics;
